@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* background-color: #f2f6fc !important; */
  /* font-family: "Roboto", sans-serif !important; */
  /* font-size: 0.9rem !important;
  color: #2b2b2b !important;
  line-height: 1.5 !important; */
  /* font-weight: 400 !important; */
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  width: 100% !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}
.bg-light {
  background-color: #ffffff !important;
}

/* button,
a,
link,
input,
select,
textarea {
  border-radius: 0px !important;
}

.table > :not(:first-child) {
  border-top: 0 !important;
} */
